.about {
  width: 100%;
  margin-top: 3%;
  padding-top: 30px;
  position: relative;
  @media (max-width: 768px) {
    padding-bottom: 30px;
  }
  .floating-image {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: end;
    right: 20px;
    bottom: 0;
    margin-bottom: -15px;
    @media (max-width: 768px) {
      z-index: -1;
      margin-bottom: -30px;
    }
    img {
      text-align: right;
      width: 35%;
      @media (max-width: 768px) {
        width: 80%;
      }
    }
  }
  .container-about {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
    .header-about {
      width: 70%;
      padding-bottom: 20px;
      @media (max-width: 768px) {
        width: 95%;
      }
      .judul-about {
        font-size: 32px;
        font-weight: 600;
        color: #733e5c;
      }
      .subjudul-about {
        font-size: 14px;
        font-weight: 400;
      }
      .sec-about {
        margin-top: 20px;
        width: 80%;
        display: flex;
        border-style: solid;
        border-width: 0px 0px 2px 0px;
        border-color: rgb(134, 134, 134);
        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;
        }
        .home-about {
          width: 80%;
          display: flex;
          margin-right: 10px;
          padding-right: 10px;
          border-style: solid;
          border-width: 0px 2px 0px 0px;
          border-color: rgb(134, 134, 134);
          @media (max-width: 768px) {
            border: none;
            width: 100%;
            padding-right: 0;
            margin-right: 0;
          }
          .icon-home-about {
            width: 5%;
            text-align: center;
            font-size: 25px;
            margin-right: 15px;
          }
          .ket-home {
            width: 60%;
            padding: 5px;
            margin-right: 20px;
            @media (max-width: 768px) {
              width: 100%;
            }
            .judul-home-about {
              font-size: 14px;
              font-family: "Poppins", sans-serif;
              font-weight: 400;
              color: rgb(178, 178, 178);
            }
            .subjudul-home-about {
              font-size: 18px;
              @media (max-width: 768px) {
                font-size: 17px;
              }
            }
          }
          .button-home-about {
            width: 10%;
            @media (max-width: 768px) {
              width: 30%;
              text-align: right;
            }
            .brosur {
              box-shadow: 0 0 2px 0 rgb(61, 61, 61);
              cursor: pointer;
              display: inline-block;
              background-color: #733e5c;
              padding: 15px 20px;
              border: none;
              color: white;
              border-radius: 5px;
              &:hover {
                color: #733e5c;
                background-color: white;
              }
            }
          }
        }
        .pembayaran-about {
          width: 100%;
          display: flex;
          margin-right: 0px;
          padding-right: 5px;
          @media (max-width: 768px) {
            margin-top: 10px;
            padding-right: 0;
            width: 100%;
          }
          .icon-pembayaran-about {
            width: 5%;
            text-align: center;
            font-size: 25px;
            margin-right: 15px;
          }
          .ket-pembayaran {
            width: 100%;
            padding: 5px;
            margin-right: 20px;
            .judul-pembayaran-about {
              font-size: 14px;
              font-family: "Poppins", sans-serif;
              font-weight: 400;
              color: rgb(178, 178, 178);
            }
            .subjudul-pembayaran-about {
              font-size: 18px;
            }
          }
          .button-pembayaran-about {
            text-align: right;
            //position: absolute;
            width: 55%;
            @media (max-width: 768px) {
              width: 100%;
            }
            .card {
              box-shadow: 0 0 2px 0 rgb(61, 61, 61);
              font-size: 12px;
              cursor: pointer;
              background-color: #25d366;
              padding: 15px 30px;
              border: none;
              color: white;
              border-radius: 5px;
              &:hover {
                color: #25d366;
                background-color: white;
              }
              @media (max-width: 768px) {
                padding: 15px 20px;
              }

              .waicon {
                display: inline-block;
              }
            }
          }
        }
      }
      .about-us {
        width: 100%;
        margin-top: 30px;
        .judul-about-us {
          font-size: 28px;
        }
      }
    }
  }
}
