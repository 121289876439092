.fasilitas {
  width: 70%;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    width: 95%;
  }
  .container-fasilitas {
    width: 100%;
    .judul-fasilitas {
      font-size: 25px;
    }
    .container-list-fasilitas {
      display: flex;
      margin-top: 10px;
      .list-fasilitas {
        width: 30%;
        margin-right: 20px;
        @media (max-width: 768px) {
          width: 95%;
        }
      }
    }
  }
}
