.devider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #733e5c;
  box-shadow: inset 0 0 10px 0 rgb(53, 53, 53);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 5%;
  .container-devider {
    width: 95%;
    @media (max-width: 768px) {
      width: 98%;
    }
    .slick-slider {
      width: 100%;
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
      }
      .slick-list {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }

      .slick-dots {
        position: absolute; /* Make dots absolute for positioning within slides */
        bottom: 10px; /* Adjust vertical positioning */
        left: 50%; /* Center dots horizontally */
        transform: translateX(-50%); /* Center dots precisely */
        z-index: 1; /* Ensure dots are above image content */
      }
      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      .slick-dots {
        li {
          margin: 0;
        }
      }
    }
  }
}
