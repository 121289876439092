.lokasi {
  width: 100%;
  margin-top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  .container-lokasi {
    width: 80%;
    @media (max-width: 768px) {
      margin-top: 3%;
      width: 96%;
    }
    .header-lokasi {
      width: 100%;
      .judul-lokasi {
        font-family: "Poppins", sans-serif;
        font-size: 36px;
        color: #733e5c;
        font-weight: 700;
      }
      .list-lokasi {
        margin-top: 10px;
      }
    }
    .image-lokasi {
      margin-top: 3%;
      width: 100%;

      .gambar {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 10px 0;
      }
    }
  }
}
